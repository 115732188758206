@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,600;0,700;0,800;1,900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;800;900&display=swap');

* {
  font-family: 'Poppins', sans-serif;
}

.logo {
  font-size: 2rem;
  width: 150px;
}

.callToAction {
  font-size: 1.2rem;
}

@media (max-width: 990px) {

  .callToAction {
    text-align: left !important;
  }

  .navItem {
    padding-left: 40px !important;
  }

  .map-container {
    height: 600px;
  }

  .titles {
    margin-top: 100px;
  }

  .ytb {
    margin-top: 49px;
    padding-bottom: 49px;
  }

  .about {
    padding: 20px;
    border: none !important;
  }

}

@media (max-width: 375px) {
  .sticky a {
    font-size: 0.7rem;
  }

  .firstNav {
    font-size: 0.9rem;
  }

}

.orange {
  background-color: #ff745d;
  padding-left: 20px;
  padding-right: 20px;
}

.orangeText {
  color: #ff745d;
}

.firstNav {
  background-color: rgb(1, 8, 16);
}

.secoundNav {
  background-color: rgba(2, 14, 26, 0.6);
}

.hero {
  background: url("./componenets/images/forestcars.jpg") 100% 70% no-repeat;
  height: 100%;
  overflow: hidden;
  background-color: rgba(14, 38, 64, 0.6);
  background-blend-mode: multiply;
  background-size: cover;
}

.pieces {
  /*padding-top: 20rem!important;*/
  padding-bottom: 15rem !important;
  background-image: url('./componenets/images/bg-01.jpg');
  background-repeat: no-repeat;
  background-position: 100% 100%;
  background-size: cover;

}

.cards {
  background-image: url('./componenets/images/BGheading.png');
  background-repeat: no-repeat;
  background-position: 100% 50%;

}

.parallax {
  top: 0;
  position: relative;
  /* The image used */
  background-image: url('./componenets/images/epave.jpg');

  /* Full height */
  height: 100%;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.hook {
  width: 100px;
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -55px;
  z-index: 4;
}

.left {
  background-color: rgba(255, 195, 38, 1);
  padding: 0;
}



.map-container {
  width: 100%;
  min-height: 100%;
}

.mapboxgl-control-container {
  display: none;
}

.titles {
  position: absolute;
  max-width: 100%;

}

.right {
  background-color: rgba(35, 35, 35, 0.90);
}

.input {
  background-color: transparent;
  border-radius: 0;
  height: 50px;
  margin-top: 50px;
  color: white;
}

.textarea {
  background-color: transparent;
  border-radius: 0;
  margin-top: 50px;
  color: white;
}

.footer {

  background-color: white;
  color: black;
}

.sticky {
  background-color: rgb(1, 8, 16);
}

.ytb {
  height: 800px;
}


/*
 * spining gears 
 */


.Loading {
  opacity: 1;
  background: rgba(255, 195, 38, 1);
  width: 100%;
  height: 100%;
  z-index: 10;
  top: 0;
  left: 0;
  position: fixed;
}

.content {
  width: 200px;
  height: 200px;

  position: absolute;
  /*Can also be `fixed`*/
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  /*Solves a problem in which the content is being cut when the div is smaller than its' wrapper:*/
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
}

.gears {
  width: 100px;
}